import styled from 'styled-components';

export const FigureStyled = styled.figure`
    width: calc(${({ theme, size }) => theme.fontSizes[size]} * 3);
    height: calc(${({ theme, size }) => theme.fontSizes[size]} * 3);

    display: inline-block;

    border-radius: 50%;
    overflow: hidden;
    border: 2px solid white;

    margin: 0;
`;

export const ImageStyled = styled.img`
    width: 100%;
`;
