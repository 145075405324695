import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { FigureStyled } from './Avatar.style';

const Avatar = ({ accessibilityLabel, children, ...props }) => (
    <FigureStyled aria-label={accessibilityLabel} role="img" {...props}>
        {Children.only(children)}
    </FigureStyled>
);

Avatar.propTypes = {
    /*
     * The label to apply to the containing span's 'aria-label' attribute
     */
    accessibilityLabel: PropTypes.string,

    /*
     * Defines the size of the avatar
     */
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),

    children: PropTypes.node,
};

Avatar.defaultProps = {
    size: 'medium',
    accessibilityLabel: 'avatar',
};

export default Avatar;
